import { graphql, PageProps } from "gatsby";
import React, { FC } from "react";
import Layout from "../components/layout";
import { SearchBar } from "../components/search-bar";
import { PageTitle } from "../components/page-title";
import { Heading1 } from "../components/heading-1";
import Seo from "../components/seo";
import { ContainerCenter } from "../components/container-center";
import * as styles from '../css/about.module.css'

const SearchPage: FC<PageProps<GatsbyTypes.AboutQuery>>=({ data, location })=>{
  const siteTitle = data.site?.siteMetadata?.title ?  data.site?.siteMetadata?.title : 'nodata';
  const description = data.site?.siteMetadata?.description;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={siteTitle} lang="ja" description={description} />
      <PageTitle pageTitle="ABOUT" discription="概要" />
      
      <ContainerCenter>
        <div className={styles.containerLeft}>
          <Heading1 text="CONCEPT"/>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.containerSubtitle}>
            {/* キャッチコピー */}
          「そうだ！」がなくなる寄付。
          </div>
          <div className={styles.containerText}>
            {/* 想定されるユーザーの課題 */}
            身近に寄付できる場所ってたくさんあるのになぜかできていない。<br></br>
            これって、自分だってお金ないのに、、そんな気持ちが誰しもあるからではないでしょうか。<br></br>
            {/* サービスでどのように課題を解決するか */}
            「寄付 = お金を払うこと」そんな常識を変えるため、このプロジェクトは生まれました。<br></br>
            いろんなサイトに表示されている広告にお金が発生していることを知っていますか？<br></br>
            その中の「アフィリエイト」という仕組みでは、広告をクリックして、商品が購入されるとサイト管理者にお金が支払われます。<br></br>
            このプロジェクトでは、この「アフィリエイト収益」を全額寄付します。<br></br>
            だから、あなたが支払うのは商品の代金だけですが、見えないところで少しだけ寄付がされるようになっています。<br></br>
            せっかく買い物するなら、ここでしませんか？<br></br>
            ちょっとだけあなたの幸せと、他の誰かの幸せのために。
          </div>
        </div>
      </ContainerCenter>


      <ContainerCenter>
        <div className={styles.containerLeft}>
          <Heading1 text="FLOW"/>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.containerSubtitle}>
            KIFU PLANET の仕組み
          </div>
          <div className={styles.containerText}>
          <ul>
            <li>
              サイトから欲しい商品を検索
            </li>
            <li>
              アフィリエイトリンクを通じてクリック
            </li>
            <li>
              アフィリエイト収益が寄付される
            </li>
          </ul>
          </div>
        </div>
      </ContainerCenter>

      <ContainerCenter>
        <div className={styles.containerLeft}>
          <Heading1 text="HOW TO DONATE"/>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.containerSubtitle}>
            寄付先と寄付金額
          </div>
          <div className={styles.containerText}>
            <div>
              現在の寄付先団体は以下の通りです。追加をご希望される寄付団体があればご連絡ください。<br></br>
              なお、アフィリエイト報酬の7%をKIFU PLANETのサービス運営費としていただいています。
            </div>
            <ul className={styles.donation}>
              <li>
                <a href="https://www.green.or.jp/bokin/" target="__blank">
                  緑の募金 - 国土緑化推進機構
                </a>
                <p>
                  植樹や間伐など国内の森林整備のほか、震災で被災した方々への支援、未来の担い手である子どもたちへの森林環境教育、また海外の緑化支援等にも使われています。
                </p>
              </li>
            </ul>
          </div>
        </div>
      </ContainerCenter>
    </Layout>
  )
}

export default SearchPage;

export const pageQuery = graphql`
query About {
  site {
    siteMetadata {
      title
      description
    }
  }
}
`